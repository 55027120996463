import React from 'react';
import { Container } from 'semantic-ui-react';
import { connect } from 'react-redux';
import thumbsUp from '../../assets/images/thumb_up-24px.svg';
import thumbsDown from '../../assets/images/thumb_down-24px.svg';
import LexxButton from '../LexxButton/lexxbutton.jsx';
import LexxBreadcrumbs from '../LexxBreadcrumbs/LexxBreadcrumbs';
import { withRouter } from "react-router-dom";
import axios from "axios";
import LexxIconHeading from '../global/LexxIconHeading';
import { feedback } from '../../redux/actions';
import { postFeedback, postStepFeedback } from '../apiCall';
import { appInsights } from "../../../utils/appInsights.js";
import KeycloakUserService from "../../components/login/KeyCloakService";

/* Component is created to get the feedback from the user after the FI/WI/WO process 
    for lexx to improve its results */
const LexxFeedback = (props) => {
    const trackFeedback = (thumbsUp) => {
        appInsights.trackEvent({
            name: "Feedback",
            properties: {
                id: props.troubleshootingData.id,
                thumbsUp: thumbsUp,
                username: KeycloakUserService.getUsername()
            }
        });
    };

    /* Function to increment the boosting when the user gives thumbs up 
        Axios call is made to backend 
    */
    const incrementActivity = () => {
        axios.defaults.params = {}
        axios.defaults.params['username'] =  props.userInfo.username;
        props.feedback(true);
        const data = { params: { id: props.troubleshootingData.id }}; 
        postFeedback(data);
        trackFeedback(true);
        if (props.troubleshootingData.info_type === 'Fault isolation') {
            let { step_activities } = props.troubleshootingData;
            step_activities[props.troubleshootingOption] += 1; // Boost the data
            let data = { id: props.troubleshootingData.id, stringBoosting: step_activities };
            postStepFeedback(data);
            appInsights.trackEvent({      
                name: "StepFeedback", 
                properties: { 
                  id: props.troubleshootingData.id,
                  stringBoosting: step_activities,
                  username: KeycloakUserService.getUsername()
                }
            });
        }
    }

    return (
        <div className="content-container home-container">
            <LexxBreadcrumbs />
            <Container className="fb-body-container">
                <LexxIconHeading text="Nice Work!" />
                <p className='section-body-text marginL3'>
                    How did I do? Give me a thumbs up or down depending on how well I did
                </p>
            </Container>
            <Container className='feedback-btn'>
                <div className='feedback-2'>
                    <LexxButton
                        primary={false}
                        text={'Not right now'}
                        act={(payload) => {
                            props.feedback(false);
                            trackFeedback(null);
                            props.history.push({
                                pathname: '/thankyou'
                            });
                        }}
                    />
                </div>
                <div className='feedback-1'>
                    <LexxButton
                        primary={true}
                        img={thumbsDown}
                        act={(payload) => {
                            props.feedback(false);
                            trackFeedback(false);
                            props.history.push({
                                pathname: '/thankyou'
                            });
                        }}
                    />
                </div>
                <div className='feedback-1'>
                    <LexxButton
                        primary={true}
                        img={thumbsUp}
                        act={(payload) => {
                            incrementActivity();
                            props.history.push({
                                pathname: '/thankyou'
                            });
                        }}
                    />
                </div>
            </Container>



        </div>
    );

};

const mapStateToProps = (state) => {
    return {
        troubleshootingData: state.troubleshootingData,
        troubleshootingOption: state.troubleshootingOption,
        userInfo: state.userInfo
    };
};

export default withRouter(connect(mapStateToProps, { feedback })(LexxFeedback));