import axios from "axios";
import Cookie from 'universal-cookie';
import KeycloakUserService from './login/KeyCloakService';

// axios.defaults.headers.common['group'] = localStorage.getItem('projectGroup', {headers});

let headers = {
    'group': localStorage.getItem('projectGroup') ? localStorage.getItem('projectGroup') : null
  };

const SIGNUP = "/users";
const ALL_SESSIONS = "/worksessions";
const START_UPDATE_SESSION = "/worksessions";
const GET_TEAM_SESSION = "/worksessions/";

const SEARCH = "/search";
const GET_PROCEDURE = "/procedure";

const GET_PROFILE = "/users/";
const GET_PROFILE_LINK = "/users/avatars/uplink/";
const PROFILE_UPLOADED = "users/avatars/key";
const CHANGE_STATUS = "/users/status";
const SAVE_PROFILE = "/users/profile";
const CALL_MADE = "/comms";

const GET_ALL_USERS = "/users";

const GET_TEAM_LIST = '/teams';

const GET_NOTIFICATIONS = "/notifications";

const POST_FEEDBACK = "/updateActivities";
const POST_STEP_FEEDBACK = "/updateStepActivities";
const LOG_DATA = "/dummy";

const FIREBASE_CALL = "/users/firebaseToken";
const GET_SOLUTIONS = "/solutions";
const SAVE_SOLUTIONS = "/solutions";
const SAVE_NEW_STEP = "/steps";
const SOLUTION_ACTIONS = "/solutions/";
const GET_STEPS = "/steps";
const STEP_ACTIONS = "/steps/";
const SPEECH_TOCKEN = "/getSpeechToken";
const LOG_ACTION = "/loguseraction";
const FEATURE_FLAG = "/feature-flags";
const SASURL ="/sasurl";

// export function setDefaultHeader () {
//     axios.defaults.headers.common['group'] = localStorage.getItem('projectGroup') ? localStorage.getItem('projectGroup') : KeycloakUserService.getGroups()[0];
// }

export async function getFeatureFlag(data){
    return axios.get(FEATURE_FLAG, {headers});
}

export async function logUserAction(data){
    return axios.post(LOG_ACTION, data, {headers});
}

export function authSignup(data){
    return axios.post(SIGNUP, data, {headers});
}

export function getAllSessions() {
  return axios.get(ALL_SESSIONS, {headers});
}

export function getSessionByTaskId(url) {
    return axios.get(ALL_SESSIONS + url, {headers});
}

export function getSingleSession(sessionID){
    return axios.get(ALL_SESSIONS + "/" + sessionID, {headers});
}

export function getTeamSession(url){
    return axios.get(GET_TEAM_SESSION + url + "/team", {headers});
}

export function faultFixed(url){
    return axios.post(GET_TEAM_SESSION + url + "/completeTime", {headers});
}

export function search(query, isTroubleshooting,filter,start, rows) {
    let headers = {
        'group': localStorage.getItem('projectGroup') ? localStorage.getItem('projectGroup') : KeycloakUserService.getGroups()[0]
      };
  return axios.post(SEARCH, { q: query, isTroubleshooting: isTroubleshooting, filter:filter, start, rows }, {headers}, {headers});
}

export function getProcedure(data){
    return axios.get(GET_PROCEDURE, {...data, headers:headers});
}

export function getSearchResultData(url, data){
    let headers = {
        'group': localStorage.getItem('projectGroup') ? localStorage.getItem('projectGroup') : KeycloakUserService.getGroups()[0]
      };
    return axios.get(url, {...data, headers:headers});
}

export function getProfile (requesterID) {
    return axios.get(GET_PROFILE + requesterID, {headers});
}

export function getProfileImageLink (requesterID, fileName) {
    return axios.get(GET_PROFILE_LINK + requesterID + fileName, {headers});
}

export function uploadProfileImage (link, file) {
    let options = { headers: { 'Content-Type': 'image/*' } };
    return axios.put(link, file, options, {headers});
}

export function profileUploaded (data) {
    return axios.post(PROFILE_UPLOADED, data, {headers});
}

export function callFunctionalityUsed(data) {
    return axios.post(CALL_MADE, data, {headers});
}

export function changeStatus(status){
    return axios.post(CHANGE_STATUS, {status: status}, {headers});
}

export function getUserProfile(userToBeRequested, requesterID){
    return axios.get(GET_PROFILE + userToBeRequested, { params: {requesterId: requesterID }, headers: headers});
}

export function saveProfile(data){
    return axios.post(SAVE_PROFILE, data, {headers});
}

export function getNotifications(){
    return axios.get(GET_NOTIFICATIONS, {headers});
}

export function markNotificationRead(url){
    return axios.post(GET_NOTIFICATIONS + url, {headers});
}

export function newSession(sessionData){
    return axios.post(START_UPDATE_SESSION, sessionData, {headers});
}

export function updateSession(url, sessionData){
    return axios.post(START_UPDATE_SESSION + url, sessionData, {headers});
}

export function getTeam(){
    return axios.get(GET_TEAM_LIST, {headers}); 
}

export function deleteTeamMember(url){
    return axios.delete(GET_TEAM_LIST + url, {headers});
}

export function getOrganizationWideUsers(){
    return axios.get(GET_ALL_USERS, {headers});
}

export function logData(data){
    return axios.post(LOG_DATA, data, {headers});
}

export function postFeedback(data){
    return axios.get(POST_FEEDBACK, {...data, headers:headers});
}

export function postStepFeedback(data){
    return axios.post(POST_STEP_FEEDBACK, data, {headers});
}

export function sendFirebaseToken(data){
    return axios.post(FIREBASE_CALL, data, {headers});
}

export function getAllSolutions(){
    return axios.get(GET_SOLUTIONS, {headers});
}

export function saveSolution(data){
    return axios.put(SAVE_SOLUTIONS,data, {headers});
}

export function saveNewStep(data, solutionId){
    return axios.put(SOLUTION_ACTIONS + solutionId + SAVE_NEW_STEP, data, {headers});
}

export function deleteSolution(id){
    return axios.delete( SOLUTION_ACTIONS +id, {headers});
}

export function getSolutionById(id) {
    return axios.get(GET_SOLUTIONS+"/"+id)
}

export function getStepBySolutionId(solutionId) {
    return axios.get(SOLUTION_ACTIONS+solutionId+GET_STEPS, {headers});
}

export function updateSolution(solutionData){
    return axios.patch(SAVE_SOLUTIONS, solutionData, {headers});
}

export function deleteSteps(solutionId, stepId) {
    return axios.delete(SOLUTION_ACTIONS + solutionId + STEP_ACTIONS + stepId, {headers});
}

export function saveEditedStep (solutionId, stepDetails, stepId) {
    return axios.patch(SOLUTION_ACTIONS+solutionId+STEP_ACTIONS+stepId, stepDetails, {headers});
}

export async function getTokenOrRefresh() {
    const cookie = new Cookie();
    const speechToken = cookie.get('speech-token');
    if (speechToken === undefined) {
        try {
            const res = await axios.get(SPEECH_TOCKEN);
            const token = res.data.token;
            const region = res.data.region;
            cookie.set('speech-token', region + ':' + token, {maxAge: 540, path: '/'});

            return { authToken: token, region: region };
        } catch (err) {
            console.log(err.response.data);
            return { authToken: null, error: err.response.data };
        }
    } else {
        const idx = speechToken.indexOf(':');
        return { authToken: speechToken.slice(idx + 1), region: speechToken.slice(0, idx) };
    }
}
export function sasUrlGeneration(url){    
    return axios.post(SASURL ,{url})
}