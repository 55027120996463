import React, { memo, useEffect, useState } from "react";
import LexxSchematicContainer from "../LexxSchematicContainer/LexxSchematicContainer";
import { Image } from "semantic-ui-react";
import { sasUrlGeneration } from "../apiCall";

/* This component is to render the images whether it is:
    1. Troubleshooting
    2. Work Instruction
 */
const LexxImage = (props) => {
  const [imgUrl, setImgUrl] = useState(props.imageData.tag_data);

  useEffect(() => {
    //the function is used to make the url signed if the url getting from backend is not signed one.
    //Most of the urls are signed from BE , but due to disparity in json structure some cannot 
    //be made signed from BE , so only those one are picked and handled from FE.
    const sasURLGenerationFunction = async () => {
      await sasUrlGeneration(props.imageData.tag_data[0]).then((res) =>
        setImgUrl( [ res.data.data.sasurl])
      );
    };
    //checking that the url is not signed 
    if(props.imageData.tag_data[0].includes("cloudfront")) sasURLGenerationFunction();
  }, [props.imageData.tag_data]);


  /* Function is to map images, if troubleshooting then call schematic component
        otherwise, its native image component.
        @obj - data which contains images
    */
  const mapTag = (obj) => {
    let images = imgUrl.map((img, key) => {
      if (props.troubleshooting ) {
        return <LexxSchematicContainer img_src={img} key={key} tag={obj.tag} />;
      } else {
        return <Image src={img} key={key} centered />;
      }
    
    });
    return <React.Fragment>{images}</React.Fragment>;
  };

  return <>{mapTag(props.imageData)}</>;
};

export default memo(LexxImage);