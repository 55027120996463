import { store } from "../redux/store/index";
import { newSession, updateSession } from "./apiCall";
import { appInsights } from "../../utils/appInsights.js";
import KeycloakUserService from "../components/login/KeyCloakService";

function getSessionState() {
  const state = store.getState();

  let sessionState =
    window.location.pathname !== "/newprocedure"
      ? {
          routerPath: window.location.pathname,
          searchResults: state.searchResults,
          troubleshootingData: state.troubleshootingData,
          troubleshootingOption:
            state.troubleshootingOption === undefined
              ? null
              : state.troubleshootingOption,
          troubleshootingStep:
            state.troubleshootingStep === undefined
              ? null
              : state.troubleshootingStep,
          visitedTroubleshootingSteps:
            state.visitedTroubleshootingSteps === undefined
              ? null
              : state.visitedTroubleshootingSteps,
          isTroubleshooting: state.isTroubleshooting,
          searchQuery: state.searchQuery,
          taskId: state.taskId,
          filterlist: state.newFilterValues,
          numfound:state.numFound,
          activepage:state.activePage,
          appliedfilter:state.newFilter
        }
      : {
          routerPath: window.location.pathname,
          searchResults: state.searchResults,
          troubleshootingData: state.troubleshootingData,
          troubleshootingOption:
            state.troubleshootingOption === undefined
              ? null
              : state.troubleshootingOption,
          troubleshootingStep:
            state.troubleshootingStep === undefined
              ? null
              : state.troubleshootingStep,
          visitedTroubleshootingSteps:
            state.visitedTroubleshootingSteps === undefined
              ? null
              : state.visitedTroubleshootingSteps,
          isTroubleshooting: state.isTroubleshooting,
          searchQuery: state.searchQuery,
          taskId: state.taskId,
          outLineRedux: JSON.parse(state.outLineRedux),
          docDetail: state.docDetail,
          fileLinkRedux: state.fileLinkRedux,
          filterlist: state.newFilterValues,
          numfound:state.numFound,
          activepage:state.activePage,
          appliedfilter:state.newFilter
        };
  return sessionState;
}

export function sessionCreate(props) {
  newSession(getSessionState())
    .then((response) => {
      if (response.data.code === "Success") {
        props.saveSessionData({
          sessionID: response.data.data.sessionId,
          isOwner: true,
        });
        props.saveTaskId(null);
        appInsights.trackEvent({      
          name: "NewSession", 
          properties: { 
            sessionId: response.data.data.sessionId,
            username: KeycloakUserService.getUsername()
          } 
      });
      }
    })
    .catch((err) => {
      console.log(err);
    });
}

export function sessionUpdate() {
  if (store?.getState()?.sessionData?.isOwner) {
    updateSession(
      "/" + store.getState().sessionData.sessionID,
      getSessionState()
    )
      .then((response) => {
        if (response.data.code === "Success") {
          // console.log(response);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
}
